<template>
  <v-layout v-if="layout.panel" class="sidebar-layout" flex="0" pos="fixed" :key="user.login">
    <v-navigation-drawer
      v-model="layout.panel"
      class="sidebar"
      width="320"
      temporary
      touchless
    >
      <div class="sidebar-container">
        <div class="sidebar-header">
          <div v-if="user.login" class="box" pd="xl">
            <NuxtLink :to="`/me`" class="box" gap="sm">
              <v-avatar
                :image="user?.thumbnail"
                :color="globalVariables.userset.color"
                class="panel-large"
              >
                <span fsize="sm">{{ user?.nickname?.slice(0, 1) }}</span>
              </v-avatar>
              <div flex="1" class="box" direction="column" gap="4xs">
                <p>{{ user.nickname }}</p>
                <p fsize="sm" color="md">{{ $t('hello') }}</p>
              </div>
            </NuxtLink>
            <span mgl="auto">
              <v-btn to="/me" :title="$t('my-profile-modify-btn')" variant="tonal" size="small" :bg="$route.name === 'me-modify-verified' ? `secondary` : `2xs`" block>
                <p break="ellipsis-1">
                  {{ $t('my-info') }}
                </p>
              </v-btn>
            </span>
          </div>
          <div v-else class="box" tpd="3xl">
            <Logo
              :type="`symbol-text`"
              :text="$t('guest')"
              :width="`23`"
            />
            <v-btn :to="`/auth/login/`" mgl="auto" variant="tonal" size="small" bg="primary" :title="$t('log-in')">
              {{ $t('log-in') }}
            </v-btn>
          </div>
          <hr class="hr" bg="5xs">
        </div>
        <div class="sidebar-contents">
          <div class="box" tpd="sm" extent="w-100">
            <component
              :is="Menus"
              :rule="`panel`"
              :menus="sideMenu"
            />
          </div>
        </div>
        <div class="sidebar-footer">
          <hr class="hr" bg="5xs">
          <div class="box" tpd="2xl" gap="xl" valign="center">
            <div mgl="sm-neg">
              <component
                :is="LanguageMenu"
              />
            </div>
            <span mgl="auto" color="xs" fsize="sm">
              {{ $t('language-info') }}
            </span>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <div v-if="layout.panel" class="sidebar-layout-background" @click="layout.panel = false">
      <div class="close-btn">
        <v-btn
          icon="mdi-close"
          variant="outlined"
          size="small"
          class="text-red-lighten-1"
        >
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'
// import LanguageMenu from '~/component/element/language-menu.vue'
// import Menus from '~/component/element/menu.vue'

export default {
  setup () {

    // 글로벌 스토어
    let $i18n: any = ref(globalVariables.app.i18n)
    const { user } = storeToRefs(useAuthStore())
    const { layout } = storeToRefs(useGlobalStore())

    // 메뉴 설정
    const sideMenu: any = ref([])
    const loginMenu = ref(globalFunctions.deepCopy(globalVariables.mainMenu.login))
    const logoutMenu = ref(globalFunctions.deepCopy(globalVariables.mainMenu.logout))
    function updateSideMenu () {
      sideMenu.value = user.value.login
                       ? globalFunctions.getTranslateText(loginMenu.value, $i18n.value)
                       : globalFunctions.getTranslateText(logoutMenu.value, $i18n.value)
    }

    // 메모리 비우기
    function restore () {
      LanguageMenu.value = null
      Menus.value = null
      $i18n.value = null
      loginMenu.value = null
      logoutMenu.value = null
    }

    // 사이즈 업데이트
    onBeforeUnmount (() => {
      restore()
    })

    onMounted(() => {
      updateSideMenu()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const LanguageMenu: any = shallowRef(null)
    const Menus: any = shallowRef(null)
    const loadComponent = async () => {
      const LanguageMenu_load = await import('~/component/element/language-menu.vue')
      const Menus_load = await import('~/component/element/menu.vue')
      LanguageMenu.value = LanguageMenu_load.default || LanguageMenu_load
      Menus.value = Menus_load.default || Menus_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,
      layout,
      sideMenu,
      updateSideMenu,

      // 컴포넌트
      LanguageMenu,
      Menus
    }
  },
  watch: {
    'user.login' () {
      this.updateSideMenu()
    },
    '$i18n.locale' () {
      this.updateSideMenu()
    }
  }
}
</script>